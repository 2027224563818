<template>
  <b-modal
    id="id-popup-quick-input-multiple-pax"
    size="lg"
    centered
    no-close-on-backdrop
    header-bg-variant="light-info"
    footer-class="justify-content-between"
    :title-class="isMobileView ? 'h4 header-class text-dark' : 'h2 header-class text-dark'"
    :title="$t('flight.quickInputPaxInfo')"
    :ok-title="$t('save')"
    :cancel-title="$t('cancel')"
    :ok-disabled="!validInput"
    @ok="parsePaxs"
  >
    <AlertGroupBooking v-if="isCreateGroupBooking" />
    <p v-html="$t('flight.quickInputPaxInfoNote')" />
    <div class="font-weight-bolder">
      {{ $t('flight.quickInputPaxInfoNoteQuantity') }}
    </div>
    <p
      class="font-weight-bolder ml-md-1"
      :class="isMobileView ? 'font-small-4' : 'font-medium-2'"
    >
      <span class="text-danger">
        {{ maxAdults }}
        {{ $tc('flight.adultPlural', maxAdults) }}

        <template v-if="maxChildren">
          -
          {{ maxChildren }}
          {{ $tc('flight.childPlural', maxChildren) }}
        </template>

        <template v-if="maxInfants">
          -
          {{ maxInfants }}
          {{ $tc('flight.infantdPlural', maxInfants) }}
        </template>
      </span>
    </p>

    <b-form-textarea
      v-model="paxs"
      rows="6"
      class="text-uppercase"
    />
  </b-modal>
</template>

<script>
import {
  BFormTextarea,
} from 'bootstrap-vue'
import {
  ref,
  computed,
} from '@vue/composition-api'

import { removeAccents } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default {
  name: 'ModalQuickInputMultiPax',

  components: {
    BFormTextarea,
    AlertGroupBooking: () => import('../result/components/AlertGroupBooking.vue'),
  },

  props: {
    maxAdults: {
      type: Number,
      default: 1,
    },
    maxChildren: {
      type: Number,
      default: 0,
    },
    maxInfants: {
      type: Number,
      default: 0,
    },
    isCreateGroupBooking: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['ok'],

  setup(props, { emit }) {
    // eslint-disable-next-line no-unused-vars
    const { toastError } = useToast()

    const paxs = ref('')
    const isValid = ref(true)

    const maxPassengers = computed(() => (props.maxAdults + props.maxChildren + props.maxInfants))

    const validInput = computed(() => {
      const cleanedPaxs = removeAccents(paxs.value).replace(/[^a-zA-Z\s\n]/g, '').replace(/ {2,}/g, ' ')
      const lines = cleanedPaxs.split('\n').filter(line => line.trim() !== '').map(line => line.trim())
      const totalPassengers = lines.length

      return totalPassengers > 0
    })

    // const adultHonorifics = ['MR', 'MRS', 'MS', 'O', 'B', 'C', 'ONG', 'BA', 'CO']
    // const childHonorifics = ['MSTR', 'MISS', 'BT', 'BG', 'BE TRAI', 'BE GAI']
    // const infantHonorifics = ['EBT', 'EBG', 'EM BE TRAI', 'EM BE GAI']

    const adultHonorifics = ['MR', 'MRS', 'MS']
    const childHonorifics = ['MSTR', 'MISS']
    const infantHonorifics = []

    const allHonorifics = [
      ...adultHonorifics,
      // childHonorifics is after infantHonorifics because need find 'EM BE TRAI', 'EM BE GAI' then find 'BE TRAI', 'BE GAI'
      ...infantHonorifics,
      ...childHonorifics,
    ]

    // const optionsMR = ['MR', 'O', 'ONG']
    // const optionsMRS = ['MRS', 'B', 'BA']
    // const optionsMS = ['MS', 'C', 'CO']
    // const optionsMSTR = ['MSTR', 'BT', 'EBT', 'BE TRAI', 'EM BE TRAI']
    // const optionsMISS = ['MISS', 'BG', 'EBG', 'BE GAI', 'EM BE GAI']

    const optionsMR = ['MR']
    const optionsMRS = ['MRS']
    const optionsMS = ['MS']
    const optionsMSTR = ['MSTR']
    const optionsMISS = ['MISS']

    function formatBirthday(birthday) {
      const parts = birthday.split('-')
      if (parts[0].length !== 4) {
        const day = parts[0]
        const month = parts[1]
        const year = parts[2]
        birthday = `${year}-${month}-${day}`
      }
      return birthday
    }

    const parsePaxs = () => {
      // ko để locale = 'en' để tránh đảo ngược tên họ
      const locale = 'vi'
      isValid.value = true

      const parsedResult = {
        ADULT: [],
        CHILD: [],
        INFANT: [],
      }

      const cleanedPaxs = removeAccents(paxs.value).replace(/[^a-zA-Z0-9\s\n/-]/g, '').replace(/ {2,}/g, ' ')
      const lines = cleanedPaxs.split('\n').filter(line => line.trim() !== '').map(line => line.trim()).map(line => line.replace(/^\d+\s/, ''))

      // const modifiedLines = lines.map((line) => line.replace(/^\d+\s/, ''));

      lines.forEach(line => {
        if (parsedResult.ADULT.length + parsedResult.CHILD.length + parsedResult.INFANT.length === maxPassengers.value) {
          return // Skip the current iteration if we already have enough passengers of each type
        }

        const cleanedLine = removeAccents(line).toUpperCase()
        let honorific = ''
        let fullName = cleanedLine
        let birthday = ''

        // Check if honorific is at the beginning of the line
        for (const h of allHonorifics) {
          const honorificRegex = new RegExp(`^${h}\\s`)
          const match = cleanedLine.match(honorificRegex)

          if (match) {
            honorific = h
            fullName = cleanedLine.replace(honorificRegex, '')
            break
          }
        }

        // Extract birthday from the line
        const birthdayRegex = /(\d{1,2}[-/]\d{1,2}[-/]\d{4}|\d{4}[-/]\d{2}[-/]\d{2})/ // bd điền ngược => bỏ
        const birthdayMatch = cleanedLine.match(birthdayRegex)

        if (birthdayMatch) {
          const replBirthday = birthdayMatch[1].replace(/\//g, '-')
          birthday = formatBirthday(replBirthday) // Replace slashes with hyphens
          fullName = fullName.replace(birthdayRegex, '').trim()
        }

        // Check if honorific is at the end of the line
        if (!honorific) {
          for (const h of allHonorifics) {
            const honorificRegex = new RegExp(`\\s${h}$`)
            const match = fullName.match(honorificRegex)

            if (match) {
              honorific = h
              fullName = fullName.replace(honorificRegex, '')
              break
            }
          }
        }

        if (!fullName) return

        fullName = fullName.replace(/[-.*+?^${}()|[\]\\]/g, '').trim()

        let firstName = ''
        let lastName = ''
        let words

        // logic for case: LE THAI/DUC => lastName = LE THAI, firstName = DUC
        if (fullName.includes('/')) {
          words = fullName.split('/')
        } else {
          words = fullName.split(' ')
        }
        const wordsCount = words.length

        if (wordsCount === 1) {
          if (locale === 'vi') {
            lastName = words[0]
          } else {
            firstName = words[0].trim()
          }
        } else if (locale === 'vi') {
          firstName = words.slice(1).join(' ').trim()
          lastName = words[0]
        } else {
          firstName = words[0].trim()
          lastName = words.slice(1).join(' ')
        }

        let paxType
        const isValidHonorific = allHonorifics.includes(honorific)

        if (isValidHonorific) {
          if (adultHonorifics.includes(honorific)) {
            if (parsedResult.ADULT.length < props.maxAdults) paxType = 'ADULT'
          } else if (childHonorifics.includes(honorific)) {
            if (parsedResult.CHILD.length < props.maxChildren) {
              paxType = 'CHILD'
            } else if (parsedResult.INFANT.length < props.maxInfants) {
              paxType = 'INFANT'
            }
          } else if (parsedResult.INFANT.length < props.maxInfants) {
            paxType = 'INFANT'
          }
        } else if (parsedResult.ADULT.length < props.maxAdults) {
          paxType = 'ADULT'
        } else if (parsedResult.CHILD.length < props.maxChildren) {
          paxType = 'CHILD'
        } else if (parsedResult.INFANT.length < props.maxInfants) {
          paxType = 'INFANT'
        }

        if (paxType) {
          let title = null

          if (optionsMR.includes(honorific)) {
            title = 'MR'
          } else if (optionsMRS.includes(honorific)) {
            title = 'MRS'
          } else if (optionsMS.includes(honorific)) {
            title = 'MS'
          } else if (optionsMSTR.includes(honorific)) {
            title = 'MSTR'
          } else if (optionsMISS.includes(honorific)) {
            title = 'MISS'
          }

          // clear số trong tên họ
          // 1.1LE/MY MRS
          // 2.1NGUYEN/TRUONG MR
          // 3.1PHAN/NHU MR
          firstName = firstName?.replace(/[0-9]/g, '')
          lastName = lastName?.replace(/[0-9]/g, '')
          title = title?.replace(/[0-9]/g, '')

          parsedResult[paxType].push({
            paxType, title, firstName, lastName, birthday,
          })
        }
      })

      // for (const key in parsedResult) {
      //   if (Object.prototype.hasOwnProperty.call(parsedResult, key)) {
      //     const paxs = parsedResult[key]
      //     if (!isEmpty(paxs)) {
      //       for (const pax of paxs) {
      //         if (pax.firstName.match(/\d/) || pax.lastName.match(/\d/)) {
      //           isValid.value = false
      //           const errData = pax.firstName.match(/\d/) || pax.lastName.match(/\d/)

      //           toastError({
      //             title: 'Lỗi định dạng thông tin hành khách',
      //             content: `Vui lòng kiểm tra thông tin dòng số ${paxs.findIndex(pax)}`,
      //           })
      //           console.log('Lỗi: Tên chứa ký tự số', { errData })
      //           return
      //         }
      //       }
      //     }
      //   }
      // }

      // if (isValid.value) {
      emit('addMuliplePassengers', parsedResult)

      // } else {
      //   // eslint-disable-next-line no-useless-return
      //   return
      // }
    }

    return {
      paxs,
      validInput,
      parsePaxs,
    }
  },
}
</script>
